import App from './pages/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ThemeProvider from './theme';
import { ApolloProvider } from '@apollo/client';
import 'connection/eagerlyConnect';
import { FeatureFlagsProvider } from 'featureFlags';
import 'polyfills';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter, useLocation } from 'react-router-dom';
import 'tracing';

import { isBrowserRouterEnabled } from 'utils/env';
import { getCanonicalUrl } from 'utils/urlRoutes';

import { BlockNumberProvider } from 'lib/hooks/useBlockNumber';

import { TooltipProvider } from 'components/ui/Tooltip';
import 'inter-ui';

import Web3Provider from './components/Web3Provider';

import '@reach/dialog/styles.css';
import 'styles/index.css';

import store from './state';
import ApplicationUpdater from './state/application/updater';
import ListsUpdater from './state/lists/updater';
import LogsUpdater from './state/logs/updater';
import OrderUpdater from './state/signatures/updater';
import TransactionUpdater from './state/transactions/updater';
import { MulticallUpdater } from 'lib/state/multicall';

import { apolloClient } from 'graphql/data/apollo';

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function Updaters() {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <link rel='canonical' href={getCanonicalUrl(location.pathname)} />
      </Helmet>
      <ListsUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <OrderUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  );
}

const queryClient = new QueryClient();

const container = document.getElementById('root') as HTMLElement;

const Router = isBrowserRouterEnabled() ? BrowserRouter : HashRouter;

createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <FeatureFlagsProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Web3Provider>
              <ApolloProvider client={apolloClient}>
                <BlockNumberProvider>
                  <Updaters />
                  <ThemeProvider>
                    <TooltipProvider>
                      <App />
                    </TooltipProvider>
                  </ThemeProvider>
                </BlockNumberProvider>
              </ApolloProvider>
            </Web3Provider>
          </Router>
        </QueryClientProvider>
      </FeatureFlagsProvider>
    </Provider>
  </StrictMode>
);

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register();
}
