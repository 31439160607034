import { ChainConfig, NetworkType } from './chain.type';

export const BaseSepoliaTestnet: ChainConfig = {
  chainId: 84532,
  network: 'base-sepolia-testnet',
  label: 'Base Sepolia Testnet',
  docs: 'https://docs.base.org',
  infoLink: 'https://info.uniswap.org/#/',
  bridge: 'https://sepolia-bridge.base.org/deposit',
  status: 'https://status.base.org',
  type: NetworkType.L2,
  explorer: 'https://sepolia.basescan.org',
  rpcs: [
    'https://rpc.dev.neby.exchange/base_sepolia',
    'https://sepolia.base.org',
  ],
  subgraph:
    'https://graph.dev.neby.exchange/subgraphs/name/uniswap-v3-base-sepolia',
  blockSubgraph:
    'https://graph.dev.neby.exchange/subgraphs/name/blocks-base-sepolia',
  harvesterSubgraph: '',
  stakerSubgraph: '',
  nebyStakerSubgraph: '',
  /* Base Sepolia - Uniswap v3 - https://docs.base.org/docs/contracts#uniswap-v3-1 */
  deployedUnixTimestamp: 0,
  feeRecipient: '0x812438AFD088369b9046A9BBF5719E84F9E8bac8',
  feePercent: 15,
  addresses: {
    v3CoreFactory: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
    multicall: '0xd867e273eAbD6c853fCd0Ca0bFB6a3aE6491d2C1',
    quoter: '0xC5290058841028F1614F3A6F0F5816cAd0df5E27', // quoterv2
    v3Migrator: '0xCbf8b7f80800bd4888Fbc7bf1713B80FE4E23E10',
    nonfungiblePositionManager: '0x27F971cb582BF9E50F397e4d29a5C7A34f11faA2',
    tickLens: '0xedf6066a2b290C185783862C7F4776A2C8077AD1',
    swapRouter02: '0x94cC0AaC535CCDB3C01d6787D6413C739ae12bc4',
    universalRouter: '0x050E797f3625EC8785265e1d9BDd4799b97528A1',
    permit2: '0x000000000022D473030F116dDEE9F6B43aC78BA3',
    harvester: '',
    liquidityPositionStaker: '',
    nebyStaker: '',
  },
  /* Base Sepolia - Uniswap v3 */
  nativeCurrency: { name: 'Base Sepolia', symbol: 'ETH', decimals: 18 },
  weth9: {
    address: '0x4200000000000000000000000000000000000006',
    symbol: 'WETH',
    decimals: 18,
    name: 'Wrapped Ether',
  },
};
