import { MenuDropdown } from '../../NavBar/MenuDropdown';
import type { ReactNode } from 'react';
import { NavLink, type NavLinkProps, useLocation } from 'react-router-dom';

import { cn } from 'utils/cn';
import { routes } from 'utils/routes';

import { useIsPoolsPage } from 'hooks/useIsPoolsPage';

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/Tooltip';

interface MenuItemProps {
  id?: NavLinkProps['id'];
  href: string;
  disabled?: boolean;
  isActive?: boolean;
  children: ReactNode;
}

const MenuItem = ({
  href,
  id,
  disabled,
  isActive,
  children
}: MenuItemProps) => {
  if (disabled) {
    return (
      <div className='cursor-default p-2 font-bold text-gray-700/50'>
        {children}
      </div>
    );
  }

  return (
    <NavLink
      to={href}
      className={cn(
        'p-2 font-bold no-underline transition-colors duration-300 hover:text-blue-900',
        {
          'text-gray-600': !isActive,
          'text-blue-900': isActive
        }
      )}
      id={id}
    >
      {children}
    </NavLink>
  );
};

const NavBar = () => {
  const { pathname } = useLocation();
  const isPoolActive = useIsPoolsPage();

  return (
    <nav className='absolute hidden w-full flex-row items-center justify-center gap-4 md:flex'>
      <MenuItem href={routes.swap} isActive={pathname.startsWith('/swap')}>
        Swap
      </MenuItem>
      <MenuItem href={routes.pools} isActive={isPoolActive}>
        Pools
      </MenuItem>
      <MenuItem
        href={routes.cosmic}
        isActive={pathname.startsWith('/cosmic')}
        disabled
      >
        <Tooltip delayDuration={0}>
          <TooltipTrigger className='cursor-default'>Cosmic</TooltipTrigger>
          <TooltipContent>Coming soon…</TooltipContent>
        </Tooltip>
      </MenuItem>
      <MenuItem href={routes.bridge} isActive={pathname.startsWith('/bridge')}>
        Bridge
      </MenuItem>
      <MenuItem href={routes.stake} isActive={pathname.startsWith('/stake')}>
        Stake
      </MenuItem>

      <MenuDropdown />
    </nav>
  );
};

export default NavBar;
