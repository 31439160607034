import type { SwapWrapperProps } from './Swap.types';
import React from 'react';

import { cn } from 'utils/cn';

import {
  InnerInwardDesign,
  OuterInwardDesign
} from 'components/composed/ContainerShapes';
import { ContainerSpaceWidth } from 'components/composed/ContainerShapes/constants';

const SwapWrapper: React.FC<SwapWrapperProps> = ({
  children,
  className,
  ...rest
}) => (
  <main
    className={cn('relative box-border w-full max-w-120', className)}
    {...rest}
  >
    <div className='relative flex flex-col'>
      <OuterInwardDesign size={ContainerSpaceWidth.OUTER_LARGE} />

      <InnerInwardDesign
        size={ContainerSpaceWidth.INNER_SWAP_TOP}
        className='absolute left-0 top-0 z-10 w-full p-1.5'
      />

      <div className='flex aspect-323/230 h-full w-full flex-col bg-blue-800 p-1.5'>
        {children}
      </div>

      <InnerInwardDesign
        size={ContainerSpaceWidth.INNER_SMALL}
        className='absolute bottom-0 left-0 z-10 w-full rotate-180 p-1.5'
      />

      <OuterInwardDesign
        size={ContainerSpaceWidth.OUTER_SMALL}
        className='rotate-180'
      />
    </div>
  </main>
);

export default SwapWrapper;
