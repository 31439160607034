import { ChainConfig, NetworkType } from './chain.type';

export const OasisSapphireMainnet: ChainConfig = {
  chainId: 23294,
  network: 'oasis-sapphire-mainnet',
  label: 'Oasis Sapphire',
  docs: 'https://docs.oasis.io/dapp/sapphire/',
  infoLink: 'https://info.uniswap.org/#/',
  type: NetworkType.L1,
  explorer: 'https://testnet.explorer.sapphire.oasis.dev',
  rpcs: ['https://sapphire.oasis.io'],
  subgraph:
    'https://api.goldsky.com/api/public/project_clzi4lu67khgw01072ibvekvt/subgraphs/uniswap-sapphire-testnet/1.0.2/gn',
  blockSubgraph:
    'https://api.goldsky.com/api/public/project_clzi4lu67khgw01072ibvekvt/subgraphs/blocks-sapphire-testnet/1.0.3/gn',
  harvesterSubgraph:
    'https://api.goldsky.com/api/public/project_clzi4lu67khgw01072ibvekvt/subgraphs/harvester-sapphire-testnet/1.0.1/gn',
  stakerSubgraph:
    'https://api.goldsky.com/api/public/project_clzi4lu67khgw01072ibvekvt/subgraphs/liquidity-position-staker-sapphire-testnet/1.0.1/gn',
  nebyStakerSubgraph:
    'https://api.goldsky.com/api/public/project_clzi4lu67khgw01072ibvekvt/subgraphs/neby-sapphire-testnet/1.0.9/gn',
  nativeCurrency: { name: 'Sapphire Rose', symbol: 'ROSE', decimals: 18 },
  deployedUnixTimestamp: 1727770450,
  feeRecipient: '0x3804581574d3A6638277322E928A302464b42271', // ProtocolFeeManager
  feePercent: 15,
  addresses: {
    v3CoreFactory: '0xf524c9e5B8C784F714016f4ae9e052f8407b8bC0',
    multicall: '0x65FC73CaE1f5431b82cAD3EA00C93C746dEbC393',
    quoter: '0x496C8294B3662c858Ae07c6b28d4f93873Ca58eE',
    v3Migrator: '0x518036Ff1328aD6aFe80505d2e7eFcD92C30a66F', // Not required
    nonfungiblePositionManager: '0xfE30f973e5A43517B93a1Ce7ed1Ae6b411bb26b8',
    tickLens: '0x23b9a91feaE1717cd8B06873a18D6CB4FDDB74D5',
    swapRouter02: '0x41294487B17a6608a1112337097d3B3d88f84167',
    harvester: '0xA04b544611b9F1FD5B6C236E15b61889740b20ca',
    liquidityPositionStaker: '0x0d849FF9F93eCF701C5D566600b15eFFCc2dE23F',
    nebyStaker: '0x411E3f4BAc81956DE6EbF9B3a8B67756F3512c87',
    // Foundry repositories
    universalRouter: '0x28EDa32759E88e2536cfA34F093273a783C4dA69',
    permit2: '0x51A0eeEd1ec83ab08f9B2bCaeb0771f0fCbD8b04',
  },
  weth9: {
    address: '0x8Bc2B030b299964eEfb5e1e0b36991352E56D2D3',
    symbol: 'wROSE',
    decimals: 18,
    name: 'Wrapped ROSE',
  },
};
