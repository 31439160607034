import { useState } from 'react';

import { Switch } from 'components/ui/Switch';

interface ToggleProps {
  id?: string;
  bgColor?: string;
  isActive: boolean;
  toggle: () => void;
}

const Toggle = ({ id, isActive, toggle }: ToggleProps) => {
  const [isInitialToggleLoad, setIsInitialToggleLoad] = useState(true);

  const switchToggle = () => {
    toggle();
    if (isInitialToggleLoad) setIsInitialToggleLoad(false);
  };

  return (
    <Switch
      checked={isActive}
      data-testid={id}
      onCheckedChange={switchToggle}
    />
  );
};

export default Toggle;
