// High-traffic pages (index and /swap) should not be lazy-loaded.
import Swap from './Swap';
import { useInfoExplorePageEnabled } from 'featureFlags/flags/infoExplore';
import { useInfoPoolPageEnabled } from 'featureFlags/flags/infoPoolPage';
import type { ReactNode } from 'react';
import { Suspense, lazy, useMemo } from 'react';
import { Navigate, matchPath, useLocation } from 'react-router-dom';

import { isBrowserRouterEnabled } from 'utils/env';
import { routes as routeList } from 'utils/routes';

const AddLiquidityWithTokenRedirects = lazy(
  () => import('pages/AddLiquidity/redirects')
);

const IncreaseLiquidity = lazy(() => import('pages/IncreaseLiquidity/index'));

const Bridge = lazy(() => import('pages/Bridge'));
const NotFound = lazy(() => import('pages/NotFound'));
const Pool = lazy(() => import('pages/Pool'));
const PoolDetails = lazy(() => import('pages/PoolDetails'));
const MyPosition = lazy(() => import('pages/MyPosition'));
const RemoveLiquidity = lazy(() => import('pages/RemoveLiquidity'));
const Stake = lazy(() => import('pages/Stake'));
const StakeCosmic = lazy(() => import('pages/StakeCosmic'));
const Cosmic = lazy(() => import('pages/Cosmic'));
const Incentivize = lazy(() => import('pages/Incentivize'));
const Whitelist = lazy(() => import('pages/Whitelist'));

interface RouterConfig {
  browserRouterEnabled?: boolean;
  hash?: string;
  infoExplorePageEnabled?: boolean;
  infoPoolPageEnabled?: boolean;
}

/**
 * Convenience hook which organizes the router configuration into a single object.
 */
export function useRouterConfig(): RouterConfig {
  const browserRouterEnabled = isBrowserRouterEnabled();
  const { hash } = useLocation();
  const infoPoolPageEnabled = useInfoPoolPageEnabled();
  const infoExplorePageEnabled = useInfoExplorePageEnabled();

  return useMemo(
    () => ({
      browserRouterEnabled,
      hash,
      infoExplorePageEnabled,
      infoPoolPageEnabled
    }),
    [browserRouterEnabled, hash, infoExplorePageEnabled, infoPoolPageEnabled]
  );
}

export interface RouteDefinition {
  path: string;
  nestedPaths: string[];
  getTitle: (path?: string) => string;
  enabled: (args: RouterConfig) => boolean;
  getElement: (args: RouterConfig) => ReactNode;
}

const defaultTabTitle = 'NEBY – Next-Gen DEX on the Oasis Network';

// Assigns the defaults to the route definition.
function createRouteDefinition(
  route: Partial<RouteDefinition>
): RouteDefinition {
  return {
    getElement: () => null,
    getTitle: () => defaultTabTitle,
    enabled: () => true,
    path: '/',
    nestedPaths: [],
    // overwrite the defaults
    ...route
  };
}

export const routes: RouteDefinition[] = [
  createRouteDefinition({
    path: routeList.home,
    getTitle: () => defaultTabTitle,
    getElement: (args) =>
      args.browserRouterEnabled && args.hash ? (
        <Navigate to={args.hash.replace('#', '')} replace />
      ) : (
        <Swap />
      )
  }),
  createRouteDefinition({
    path: routeList.bridge,
    getTitle: () => defaultTabTitle,
    getElement: () => (
      <Suspense fallback={null}>
        <Bridge />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/swap',
    getElement: () => <Swap />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({ path: '/pool', getElement: () => <Pool /> }),
  createRouteDefinition({
    path: '/pool/:address',
    getTitle: () => defaultTabTitle,
    getElement: () => (
      <Suspense fallback={null}>
        <PoolDetails />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: routeList.pools,
    getElement: () => <Pool />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/pools/:address',
    getTitle: () => defaultTabTitle,
    getElement: () => (
      <Suspense fallback={null}>
        <PoolDetails />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/add-liquidity',
    nestedPaths: [
      ':currencyIdA',
      ':currencyIdA/:currencyIdB',
      ':currencyIdA/:currencyIdB/:feeAmount',
      ':currencyIdA/:currencyIdB/:feeAmount/:tokenId'
    ],
    getElement: () => <AddLiquidityWithTokenRedirects />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/increase-liquidity/:currencyIdA/:currencyIdB/:feeAmount/:tokenId',
    getElement: () => <IncreaseLiquidity />,
    getTitle: () => 'Increase Liquidity to Pools'
  }),
  createRouteDefinition({
    path: '/my-position/:tokenId',
    getTitle: () => defaultTabTitle,
    getElement: () => (
      <Suspense fallback={null}>
        <MyPosition />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/remove-liquidity/:tokenId',
    getElement: () => <RemoveLiquidity />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/stake-cosmic',
    nestedPaths: [
      ':currencyIdA/:currencyIdB',
      ':currencyIdA/:currencyIdB/:tokenId'
    ],
    getElement: () => <StakeCosmic />,
    getTitle: () => 'Stake in Cosmic'
  }),
  createRouteDefinition({
    path: '/stake',
    getElement: () => <Stake />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '/cosmic',
    getElement: () => <Cosmic />,
    getTitle: () => 'Cosmic'
  }),
  createRouteDefinition({
    path: '/incentivize',
    getElement: () => <Incentivize />,
    getTitle: () => 'Incentivize'
  }),
  createRouteDefinition({
    path: '/whitelist',
    getElement: () => <Whitelist />,
    getTitle: () => defaultTabTitle
  }),
  createRouteDefinition({
    path: '*',
    getElement: () => <Navigate to='/not-found' replace />
  }),
  createRouteDefinition({ path: '/not-found', getElement: () => <NotFound /> })
];

export const findRouteByPath = (pathname: string) => {
  for (const route of routes) {
    const match = matchPath(route.path, pathname);
    if (match) {
      return route;
    }
    const subPaths = route.nestedPaths.map(
      (nestedPath) => `${route.path}/${nestedPath}`
    );
    for (const subPath of subPaths) {
      const match = matchPath(subPath, pathname);
      if (match) {
        return route;
      }
    }
  }
  return undefined;
};
